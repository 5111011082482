.top_logo{
        width: 40px;
        height: 40px;
        margin-left: 6px;
        margin-top: 10px;
}

// .ScrollStyle
// {
//     float:left;
//    width:1000px;
//    overflow: auto;

// }

.ScrollStyle_sidebar
{
        float: left;
        height: 600px;
        width: 254px;
        overflow-y: auto;
        overflow-x: hidden;
}
#vertical_scroll_sidebar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgb(152,152,152);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#vertical_scroll_sidebar::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}
#vertical_scroll_sidebar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgb(152,152,152);
	background-color: #555;
}
.copyright{
        margin-left: 19px;;
}