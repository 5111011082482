.heading{
    margin-left: 20px;
    margin-top: 10px;
}


// .ScrollStyle
// {
//     float:left;
//    width:400px;
//    overflow: auto;
//    height:600px;
//    overflow-x: hidden;

// }

.ScrollStyle
{
	float: left;
	height: 600px;
	width: 437px;
    overflow-y: auto;
	overflow-x: hidden;
	
}
#vertical_scroll::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgb(152,152,152);
	border-radius: 10px;
	background-color: #F5F5F5;
	scrollbar-width: none; /* Firefox */
}

#vertical_scroll::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
	scrollbar-width: none; /* Firefox */
}
#vertical_scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgb(152,152,152);
	background-color: #555;
	scrollbar-width: none; /* Firefox */
}


.csv-btn
{
    margin-left: 365px;
    color: white;
}

#export_btn{
    float:right;
    background-Color:#4B0082;
}

#google_translate_element{
    margin-top: 24px;
	height:30px;
    margin-right: 83px;
}

.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    border: 1px solid #f1ecec;
    /* padding: 5px; */
    /* padding: 4px; */
	outline: 4px solid #f7f1f1;
}